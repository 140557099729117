import SideNav from '../../SideNav/SideNav';
import classes from './PersonalInfo.module.scss';
import i18n from 'i18next';

const PersonalInfo = (props) => {
    const addClass = (ev) => {
        ev.currentTarget.parentElement.classList.toggle(classes.active);
    }

    return(
        <SideNav name={i18n.t("personal info")}>
            <div className={`${classes.PersonalInfo} ${classes.active}`}>   
                <div onClick={(ev)=>addClass(ev)} >
                    <svg id="icon-chevron-right1" viewBox="0 0 19 28">
                        <path d="M17.297 13.703l-11.594 11.594c-0.391 0.391-1.016 0.391-1.406 0l-2.594-2.594c-0.391-0.391-0.391-1.016 0-1.406l8.297-8.297-8.297-8.297c-0.391-0.391-0.391-1.016 0-1.406l2.594-2.594c0.391-0.391 1.016-0.391 1.406 0l11.594 11.594c0.391 0.391 0.391 1.016 0 1.406z"></path>
                    </svg>
                    <svg viewBox="0 0 32 32">
                        <path d="M16 9l-2-4h-10.997c-1.106 0-2.003 0.89-2.003 1.991v18.018c0 1.1 0.9 1.991 1.993 1.991h25.013c1.101 0 1.993-0.893 1.993-1.995v-14.010c0-1.102-0.897-1.995-2.006-1.995h-11.994z"></path>
                    </svg>
                <p>{i18n.t("bio")}</p>
                </div>
                <div>
                    <div id='0' className={props.class} onClick={props.onClick}>
                        <svg id="icon-insert_drive_file" viewBox="0 0 24 24">
                            <path d="M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z"></path>
                        </svg>
                        <p>Dovran Akyyev</p>
                    </div>
                    <div id='1' onClick={props.onClick}>
                        <svg id="icon-insert_drive_file" viewBox="0 0 24 24">
                            <path d="M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z"></path>
                        </svg>
                        <p>Sahy Guvanchmyradov</p>
                    </div>
                    <div id='2' onClick={props.onClick}>
                        <svg id="icon-insert_drive_file" viewBox="0 0 24 24">
                            <path d="M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z"></path>
                        </svg>
                        <p>Ahmet Akyyev</p>
                    </div>
                    <div id='3' onClick={props.onClick}>
                        <svg id="icon-insert_drive_file" viewBox="0 0 24 24">
                            <path d="M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z"></path>
                        </svg>
                        <p>Sapardurdy Berdiyev</p>
                    </div>
                    <div id='4' onClick={props.onClick}>
                        <svg id="icon-insert_drive_file" viewBox="0 0 24 24">
                            <path d="M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z"></path>
                        </svg>
                        <p>Dovran Jumakulyyev</p>
                    </div>
                    <div id='5' onClick={props.onClick}>
                        <svg id="icon-insert_drive_file" viewBox="0 0 24 24">
                            <path d="M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z"></path>
                        </svg>
                        <p>Davut Akyyev</p>
                    </div>
                    <div id='6' onClick={props.onClick}>
                        <svg id="icon-insert_drive_file" viewBox="0 0 24 24">
                            <path d="M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z"></path>
                        </svg>
                        <p>Mekan Cherkezov</p>
                    </div>
                </div>
            </div>
            {/* <div className={classes.PersonalInfo}>   
                <div onClick={(ev)=>addClass(ev)}>
                    <svg id="icon-chevron-right1" viewBox="0 0 19 28">
                        <path d="M17.297 13.703l-11.594 11.594c-0.391 0.391-1.016 0.391-1.406 0l-2.594-2.594c-0.391-0.391-0.391-1.016 0-1.406l8.297-8.297-8.297-8.297c-0.391-0.391-0.391-1.016 0-1.406l2.594-2.594c0.391-0.391 1.016-0.391 1.406 0l11.594 11.594c0.391 0.391 0.391 1.016 0 1.406z"></path>
                    </svg>
                    <svg viewBox="0 0 32 32">
                        <path d="M16 9l-2-4h-10.997c-1.106 0-2.003 0.89-2.003 1.991v18.018c0 1.1 0.9 1.991 1.993 1.991h25.013c1.101 0 1.993-0.893 1.993-1.995v-14.010c0-1.102-0.897-1.995-2.006-1.995h-11.994z"></path>
                    </svg>
                <p>interests</p>
                </div>
                <div>
                </div>
            </div>
            <div className={classes.PersonalInfo}>   
                <div onClick={(ev)=>addClass(ev)}>
                    <svg id="icon-chevron-right1" viewBox="0 0 19 28">
                        <path d="M17.297 13.703l-11.594 11.594c-0.391 0.391-1.016 0.391-1.406 0l-2.594-2.594c-0.391-0.391-0.391-1.016 0-1.406l8.297-8.297-8.297-8.297c-0.391-0.391-0.391-1.016 0-1.406l2.594-2.594c0.391-0.391 1.016-0.391 1.406 0l11.594 11.594c0.391 0.391 0.391 1.016 0 1.406z"></path>
                    </svg>
                    <svg viewBox="0 0 32 32">
                        <path d="M16 9l-2-4h-10.997c-1.106 0-2.003 0.89-2.003 1.991v18.018c0 1.1 0.9 1.991 1.993 1.991h25.013c1.101 0 1.993-0.893 1.993-1.995v-14.010c0-1.102-0.897-1.995-2.006-1.995h-11.994z"></path>
                    </svg>
                <p>education</p>
                </div>
                <div>
                    
                    
                </div>
            </div> */}
        </SideNav>
    );
};

export default PersonalInfo;