import Nav from '../../components/Projects/Nav/Nav';
import Project from '../../components/Projects/Project/Project';
import classes from './Projects.module.scss';


const Projects = () => {

    return(
        <section className={`Container ${classes.Projects__box}`}>
            <Nav/>
            <div className={classes.Projects}>
                <Project text="Pikirleňizi beýan ediň."/>
            </div>
        </section>
    );
};

export default Projects;