import classes from './Code.module.scss';

const Code = (props) => {
    let date = new Date();
    let day = date.getDate();
    let month = date.toLocaleString("en-US", { month: "short" }) ;
    let year = date.getFullYear();
    return(
        <div className={classes.Code}>
            <div>
                <pre>
                    <span className='Violet'>const</span> message <span className='Violet'>=</span> <span className='Gray'>&#123;</span><br/>
                    <span className='ml'></span>name: <span className='Orange2'>"{props.name}"</span><span className='Gray'>,</span><br/>
                    <span className='ml'></span>email: <span className='Orange2'>"{props.email}"</span><span className='Gray'>,</span><br/>
                    <span className='ml'></span>message: <span className='Orange2'>"{props.message}"</span><span className='Gray'>,</span><br/>
                    <span className='ml'></span><span className='Gray'>date:</span> <span className='Orange2'>"{`${month} ${day} ${year}`}"</span><br/>
                    <span className='Gray'>&#125;</span><br/>
                    <br/><br/>
                    button.addEventListener(<span className='Orange2'>'click'</span><span className='Gray'>, ()</span> <span className='Violet'>=&#62;</span> <span className='Gray'>&#123;</span><br/>
                    <span className='ml'></span>form.send<span className='Gray'>(</span>message<span className='Gray'>)</span>;<br/>
                    <span className='Gray'>)&#125;</span><br/>
                </pre>
            </div>
        </div>
    );
};

export default Code;
