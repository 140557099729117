import classes from './Form.module.scss';

const Form = (props) => {
    return(
        <div className={classes.Form}>
            <section>
                <div>
                    <p>_name:</p>
                    <input type="text" name='name' autoComplete='off' onChange={props.onChange}/>
                </div>
                <div>
                    <p>_email:</p>
                    <input type="email" name='email'  autoComplete='off' onChange={props.onChange}/>
                </div>
                <div>
                    <p>_message:</p>
                    <textarea name='message' onChange={props.onChange}/>
                </div>
                <button>submit-message</button>
            </section>
        </div>
    );
};

export default Form;