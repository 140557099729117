import { useEffect, useReducer } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import classes from './Header.module.scss';
import ru from '../../assets/img/_ru.png';
import us from '../../assets/img/_us.png';
import { useTranslation } from "react-i18next";
import { useDetectClickOutside } from "react-detect-click-outside";

const Header = () => {
    let { t } = useTranslation();
    let navigate = useNavigate();
    const initial = {
        currentLang: {
            img: ru,
            short: 'ru',
            long: 'Russian'
        },
        lang : [
            {
                img: ru,
                short: 'ru',
                long: 'Russian'
            },
            {
                img:us,
                short: 'en',
                long: 'English'
            }
        ],
        show: false,
        closeShow:false
    }
    let location = useLocation();
    const reducer = (state, action) => {
        switch(action.type) {
            case 'setShow':return {...state, show: !state.show};
            case 'setCloseShow': return{...state, closeShow: !state.closeShow}; 
            case 'setShowFalse': return{...state, show:false};
            case 'setCloseShowFalse': return{...state, closeShow:false};
            case 'ru': return{...state, currentLang:state.lang[0]};
            case 'en': return{...state, currentLang:state.lang[1]};
            case 'setCurrentLang':
            localStorage.setItem('lang', action.payload.querySelector('img').alt);
            navigate(0);
            return{...state, 
                currentLang:{img: action.payload.querySelector('img').src, 
                short: action.payload.querySelector('img').alt, 
                long:action.payload.querySelector('p').textContent}};
            case 'setLocalLang': {
            const curLang = action.payload === 'ru' ? 0 : 1;
            return{...state, 
                currentLang:state.lang[curLang]}; 
            }
            default: return state;
        }
    }
    const [state, dispatch] = useReducer(reducer, initial);
    const close = () =>{
        dispatch({type:'setCloseShowFalse'});
    };
    const ref = useDetectClickOutside({ onTriggered: close});

    useEffect(()=>{
        dispatch({type:'setShowFalse'});
        
          let localLang = localStorage.getItem('lang');
          if(localLang) {
            dispatch({type: 'setLocalLang', payload:localLang}); 
          };
    },[location.pathname]);
    return(
        <header className={`${classes.Header} Container `}>
            <Link to="/" className={classes.Header__coName}>akylly-cozgut</Link>
            <div className={state.show? classes.active:''}>
                <NavLink className={location.pathname === '/'? classes.active : ''} to="/">_{t('hello')}</NavLink>
                <NavLink className={location.pathname === '/about-us'? classes.active : ''} to="/about-us">_{t('aboutUs')}</NavLink>
                <NavLink className={location.pathname === '/projects'? classes.active : ''} to="/projects">_{t('projects')}</NavLink>
                <NavLink className={location.pathname === '/contact-us'? classes.active : ''} to="/contact-us">_{t('contactUs')}</NavLink>
                <div ref={ref} className={`${classes.Header__lang } ${state.closeShow? classes.active : null}`} onClick={()=>{dispatch({type:'setCloseShow'})}}>
                    <div>
                        <img src={state.currentLang.img} alt={state.currentLang.short}/>
                        <p>{state.currentLang.long}</p>
                        <svg viewBox="0 0 24 24">
                            <path d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                        </svg>
                    </div>
                    {state.closeShow?
                        <ul >
                            {
                                state.lang.map((el,index)=>{
                                    if(state.currentLang.short === el.short) {
                                        return null;
                                    }else{
                                        return <li onClick={(ev)=>{dispatch({type:'setCurrentLang',payload:ev.currentTarget}); dispatch({type:'setShowFalse'})}} key={`lang_${el.short}`}>
                                        <img  src={el.img} alt={el.short}/>
                                        <p>{el.long}</p>
                                    </li>
                                    }
                                    
                                })
                            }
                        </ul>:null
                    }
                    
                </div>
            </div>
            <h1 onClick={()=>{dispatch({type:'setShow'})}} >
                <span className={state.show? classes.active:''}></span>
            </h1>

        </header>
    );
};

export default Header;