import classes from './Project.module.scss';
import img from '../../../assets/img/beyan.png';
import i18n from 'i18next';

const Project = (props) => {
    return(
        <div className={classes.Project}>
            <h1><span>Project 1</span> &#47;&#47; _beyan</h1>
            <div>
                <figure>
                    <img src={img} alt="Beyan" />
                </figure>
                <article>
                    <p>{props.text}</p>
                    <a href='https://beyan.wiki/' rel="noopener noreferrer" target="_blank">{i18n.t('viewProject')}</a>
                </article>
            </div>
        </div>
    );
};

export default Project;