import SideNav from '../../SideNav/SideNav';
import classes from './Contacts.module.scss';
import { useTranslation } from 'react-i18next';

const Contacts = () => {
    const {t} = useTranslation();

    return(
        <SideNav name={t("contacts")}>
            <div className={classes.Contacts}>
                <svg id="icon-email" viewBox="0 0 24 24">
                    <path d="M20.016 8.016v-2.016l-8.016 5.016-8.016-5.016v2.016l8.016 4.969zM20.016 3.984q0.797 0 1.383 0.609t0.586 1.406v12q0 0.797-0.586 1.406t-1.383 0.609h-16.031q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.406t1.383-0.609h16.031z"></path>
                </svg>
            <p>info@akyllycozgut.com</p>
            </div>
            <div className={classes.Contacts}>
                <svg id="icon-call" viewBox="0 0 24 24">
                    <path d="M20.016 15.375q0.422 0 0.703 0.281t0.281 0.703v3.469q0 1.172-0.984 1.172-6.984 0-12-5.016t-5.016-12q0-0.984 1.172-0.984h3.469q0.422 0 0.703 0.281t0.281 0.703q0 1.828 0.563 3.516 0.188 0.609-0.234 1.031l-1.922 1.688q2.156 4.5 6.891 6.797l1.547-1.969q0.281-0.281 0.703-0.281 0.234 0 0.328 0.047 1.688 0.563 3.516 0.563z"></path>
                </svg>
                <p>+99362430419</p>
            </div>
        </SideNav>
    );
};

export default Contacts;