import classes from './Home.module.scss';
import Dino from '../../components/Dino/Dino';
import { useTranslation } from "react-i18next";

const Home = () => {
    const {t} = useTranslation();
    document.addEventListener('keypress', (ev)=>{
        if(ev.code === "Space") {
            const button = document.querySelector(`.${classes.Home__startButton}`);
            button.classList.add(classes.not);
        }
    });

    return(
        <section className={classes.Home}>
            <div className={classes.Home__headers}>
                <h1>{t("helloAll")}</h1>
                <h2>Akylly Cozgut</h2>
                <h3>&#62; {t('developerGroup')}</h3>
                {/* <p>&#47;&#47; complete the game to continue</p> */}
                <p>&#47;&#47; {t("seeGit")} </p>
                <p><span className='Blue'>const</span> <span className='Green'>githubLink</span> = <a rel="noopener noreferrer" href='https://github.com/akyllycozgut' target="_blank" className='Orange'>"https://github.com/akyllycozgut"</a></p>
            </div>
            <div>
                <Dino />
                <button className={classes.Home__startButton}>{t('pressSpace')}</button>
            </div>
        </section>
    );
};

export default Home;