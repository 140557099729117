import SideNav from '../../SideNav/SideNav';
import classes from './Nav.module.scss';
import { useTranslation } from 'react-i18next';

const Nav = () => {
    const {t} = useTranslation();

    return(
        <div className={classes.Nav}>
            <SideNav name={t("projects")}>
                <ul >
                    <li>React</li>
                    <li>Node JS</li>
                    <li>PHP, Laravel</li>
                    <li>Flutter</li>
                    <li>UX/UI</li>
                    <li>Branding</li>
                </ul>
            </SideNav>
        </div>
    );
};

export default Nav;