import "./App.scss";
import {Routes,Route, HashRouter} from 'react-router-dom';
import MainContainer from "./containers/MainContainer/MainContainer";
import Home from "./containers/Home/Home";
import AboutUs from "./containers/AboutUs/AboutUs";
import Projects from "./containers/Projects/Projects";
import ContactUs from "./containers/ContactUs/ContactUs";

function App() {
  return (
      <div className="App">
        <HashRouter>
            <Routes>
                <Route element={<MainContainer/>}>
                    <Route path="/"element={<Home/>}/>
                    <Route path="/about-us" element={<AboutUs/>}/>
                    <Route path="/projects" element={<Projects/>}/>
                    <Route path="/contact-us" element={<ContactUs/>}/>
                </Route>
                <Route path="*" element={<div>Name isleyan</div>}/>
            </Routes>
            </HashRouter>
      </div>
  );
}

export default App;
