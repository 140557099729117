import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import {Outlet, useLocation} from 'react-router-dom';
import classes from './MainContainer.module.scss';
import { useEffect } from 'react';

const MainContainer = ()=> {
    let location = useLocation();

    useEffect(()=>{
        document.documentElement.scrollTo(0, 0);
    },[location.pathname])
    return(
        <main className={`Container ${location.pathname === '/'? 'home' : ''} ${classes.MainContainer}`}>
            <Header/>
                <Outlet/>
            <Footer/>
        </main>
    );
};

export default MainContainer;