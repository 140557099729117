import classes from './SideNav.module.scss';

const SideNav = (props) => {
    let media = window.matchMedia("(max-width: 800px)");
    const addClass = (ev) => {
        ev.currentTarget.parentElement.classList.toggle(classes.active);
    }

    return(
        <div className={`${classes.SideNav} ${!media.matches ?classes.active : ''}`}>
            <div onClick={(ev)=>addClass(ev)}>
                <svg id="icon-caret-down" viewBox="0 0 16 28">
                    <path d="M15.998 10.5c0 0.266-0.109 0.516-0.297 0.703l-7 7c-0.187 0.187-0.438 0.297-0.703 0.297s-0.516-0.109-0.703-0.297l-7-7c-0.187-0.187-0.297-0.438-0.297-0.703 0-0.547 0.453-1 1-1h14c0.547 0 1 0.453 1 1z"></path>
                </svg>
                <p>{props.name}</p>
            </div>
            <div>
                {props.children}
            </div>
        </div>
    );
};

export default SideNav;