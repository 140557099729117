import { useReducer } from 'react';
import Code from '../../components/ContactUs/Code/Code';
import Form from '../../components/ContactUs/Form/Form';
import Nav from '../../components/ContactUs/Nav/Nav';
import classes from './ContactUs.module.scss';

const ContactUs = () => {
    const initial = {
        name: "",
        email: "",
        message: "",
    }

    const reducer = (state, {field,value}) => {
        return {...state, 
            [field]: value
        };
    }

    const [changed, dispatch] = useReducer(reducer,initial);

    const changeHandler =(e)=> {
        dispatch({field: e.target.name, value: e.target.value});
    }
    return(
        <section className={`Container ${classes.ContactUs__box}`}>
            <Nav/>
            <div className={classes.ContactUs}>
                <Form onChange={changeHandler}/> 
                <Code name={changed.name} email={changed.email} message={changed.message} />
            </div>
        </section>
    );
};

export default ContactUs;