import i18n from "i18next";
import { initReactI18next  } from "react-i18next";
import LngDetector from 'i18next-browser-languagedetector';
import rulang from './ru.json';
import enlang from './en.json';

export default i18n
  .use(initReactI18next)
  .use(LngDetector) // passes i18n down to react-i18next
  .init({
    resources: {
      ru: {
        translation: rulang
      },
      en: {
        translation: enlang
      },
    },
    fallbackLng: 'en',
    detection: {
    order: ['localStorage', ],
    lookupLocalStorage: 'lang',
    caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
