import { useState } from 'react';
import Contacts from '../../components/AboutUs/Contacts/Contacts';
import PersonalInfo from '../../components/AboutUs/PersonalInfo/PersonalInfo';
import classes from './AboutUs.module.scss';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
    const {t} = useTranslation();

    const [text, setText] = useState({
        text: t("dovranA"),
        github: null,
        instagram:null,
        twitter: null,
        linkedIn: null,
        gmail: "dewran.ak@gmail.com",

    });
    const group = {
        dovranA:{
            text: t("dovranA"),
            github: null,
            twitter: null,
            instagram:null,
            linkedIn: null,
            gmail: "dewran.ak@gmail.com",

        },
        sahy: {
            text: t("sahy"),
            github: "https://github.com/Sahygeldi2003",
            instagram: "https://www.instagram.com/guvanchmyrad_off/",
            twitter: null,
            linkedIn: null,
            gmail: "guvanchmyradovsahy@gmail.com",

        },
        ahmet: {
            text: t("ahmet"),
            github: "https://github.com/ahmetakyyev",
            instagram: "https://instagram.com/ahmet_akyyev",
            twitter: "https://twitter.com/yadow_",
            linkedIn: "https://www.linkedin.com/in/ahmet-akyyev-4a448a22a",
            gmail: "ahmetakyyev0@gmail.com",

        },
        sapar: {
            text: t("sapar"),
            github: null,
            instagram:null,
            twitter: null,
            linkedIn: null,
            gmail: "berdiyev.sapardurdy@gmail.com",

        },
        dovranJ: {
            text: t("dovranJ"),
            github: null,
            instagram: null,
            twitter: null,
            linkedIn: null,
            gmail: "ddowran2106@gmail.com",

        },
        davut: {
            text: t("davut"),
            github: "https://github.com/DavutAkyyev",
            twitter: null,
            instagram: "https://www.instagram.com/davut_akyyev/",
            linkedIn: null,
            gmail: "akydawut@gmail.com",

        },
        mekan: {
            text: t("mekan"),
            github: "github.com/CasperCMD",
            twitter: null,
            instagram: null,
            linkedIn: "www.linkedin.com/in/mekan-cherkezov-240a07263",
            gmail: "casper.cmd@gmail.com",

        },
    };


    return(
        <section className={`Container ${classes.AboutUs__box}`}>  
            <div className={classes.AboutUs__nav}>
                <PersonalInfo class={classes.active} onClick={(ev)=>{
                    if(Object.keys(text).length > 0){
                        let current = document.querySelector(`.${classes.active}`);
                        current.classList.remove(classes.active);
                        ev.currentTarget.classList.add(classes.active);
                    }else {
                        ev.currentTarget.classList.add(classes.active);
                    }
                    setText(Object.values(group)[ev.currentTarget.id])}}/>
                <Contacts/>
            </div>
            <div className={classes.AboutUs}>
                <div>
                    <pre>
                        <span>{text.text}</span>
                    </pre>
                </div>
                { Object.keys(text).length > 0?<div>
                    <h1>{t("contacts")}:</h1>
                    {text.github !==null? <p><span className='Blue'>const </span><span className='Green'>githubLink</span> = <a rel="noopener noreferrer" href={`${text.github}`} target="_blank" className='Orange'>{text.github}</a>;</p>: ''}
                    {text.linkedIn !==null? <p><span className='Blue'>const </span><span className='Green'>linkedInLink</span> = <a rel="noopener noreferrer" href={`${text.linkedIn}`} target="_blank" className='Orange'>{text.linkedIn}</a>;</p>: ''}
                    {text.twitter !==null? <p><span className='Blue'>const </span><span className='Green'>twitterLink</span> = <a rel="noopener noreferrer" href={`${text.twitter}`} target="_blank" className='Orange'>{text.twitter}</a>;</p>: ''}
                    {text.instagram !==null? <p><span className='Blue'>const </span><span className='Green'>instagramLink</span> = <a rel="noopener noreferrer" href={`${text.instagram}`} target="_blank" className='Orange'>{text.instagram}</a>;</p>: ''}
                    {text.gmail !==null? <p><span className='Blue'>const </span><span className='Green'>gmailLink</span> = <span className='Orange'>{text.gmail}</span>;</p>: ''}
                </div>:''
                }
                
            </div>
        </section>
    );
};

export default AboutUs;